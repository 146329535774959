'use client'; // Error components must be Client Components

import { deauthenticate } from '@/app/lib/actions';
import {
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  FaceFrownIcon,
} from '@heroicons/react/24/outline';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  // useEffect(() => {
  //   // Log the error to an error reporting service
  //   console.error(error);
  // }, [error]);

  return (
    <html>
      <body className="h-full w-full">
        <main className="flex h-screen flex-col items-center justify-center gap-2 bg-blue-50">
          <FaceFrownIcon className="w-10 text-gray-400" />
          <h2 className="text-xl font-semibold">發生神秘錯誤！</h2>
          <div className="text-xs">digest: {error?.digest}</div>
          <div className="mt-4 flex gap-3">
            <button
              onClick={() => reset()}
              className="flex justify-center gap-1 rounded-md bg-gray-200 px-4 py-2 text-sm text-gray-600 transition-colors hover:bg-gray-300"
            >
              <ArrowPathIcon className="h-5 w-5" />
              再試一次
            </button>
            <button
              onClick={async () => {
                await deauthenticate();
              }}
              className="bg-apricot-button hover:bg-apricot-button_hover flex justify-center gap-1 rounded-md px-4 py-2 text-sm text-white transition-colors"
            >
              <ArrowRightOnRectangleIcon className="h-5 w-5" />
              重新登錄
            </button>
          </div>
        </main>
      </body>
    </html>
  );
}
